import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Toolbar from '../../components/Toolbar/Toolbar'
import Table from '../../components/Table/Table';
import optionsParser from '../../helpers/optionsParser';
import Loading from '../../components/Loading/Loading';
import Collapse from '@material-ui/core/Collapse';
import Columns from '../../components/Columns';
import Filter from '../../components/Filter/Filter';
import { api_delete, api_get, get_file } from "../../utils/Api";
import { SearchUrlHandler } from 'helpers/methods';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const TeacherList = () => {
  const columnsList = [
    { accessor: 'id', name: 'id', label: 'Id', width: '10%', show: true, sortable: true, type: 'userSwitch' },
    { accessor: 'last_name', label: 'lastName', width: '20%', show: true },
    { accessor: 'name', label: 'Name', width: '20%', show: true },
    { accessor: 'email', label: 'Email', width: '30%', show: true },
    { accessor: 'phone', label: 'Phone', width: '30%', show: true },
    { accessor: 'external_teacher', type: 'boolean', color: 'primary', label: 'ExternalTeacher', show: true, width: '10%', },
    { accessor: 'is_admin_teacher', type: 'boolean', color: 'primary', label: 'AdminTeacher', show: true, width: '10%', },
    { accessor: 'verified', type: 'boolean', color: 'primary', label: 'Verified Teacher', show: true, width: '30%', },
    { accessor: 'created_at', name: 'createdAt', label: 'createdAt', width: '10%', show: true, sortable: true, type: 'date' },
    { accessor: 'instructor_subjects', label: 'Subjects', width: '30%', show: true, type: 'subjectType' },
  ];
  const fieldSearchable = [
    'id',
    'name',
    'lastName',
    'email',
    'phone',
  ];
  const fieldFilterable = [
    { name: 'instructorSubjects.subject.id', label: 'Subject', type: 'subjectAutocomplete' },
    { name: 'instructorSubjects.subject.domain.id', label: 'Domain', type: 'domainAutocomplete' },
    { name: 'instructorSubjects.subject.division.id', label: 'Division', type: 'divisionAutocomplete' },
    { name: 'isAdminTeacher', label: 'Admin Teacher', type: 'boolean' },
    { name: 'externalTeacher', label: 'External Teacher', type: 'boolean' },
  ];
  const [search, searchChange] = useState('');
  const [filters, filtersChange] = useState({});
  const [isSearch, setIsSearch] = useState(false);
  const [columns, columnsChange] = useState(columnsList);
  const [sort, sortChange] = useState({ accessor: "id", order: "desc" });
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [columnsOpen, setColumnsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(12);



  useEffect(() => {
    getData();
    const filterButton = localStorage.getItem('FilterButton')
    setFiltersOpen(filterButton);
  }, [page, sort]);

  useEffect(() => {
    const options = optionsParser(search, filters, sort, fieldSearchable);
    if (search.length) {
      setIsSearch(true)
      setPage(1);
      //getData();
      api_get(`instructors?page=${page}${window.location.search ? SearchUrlHandler(window.location.search) : options}&searchJoin=or`).then((data) => {
        setData(data);
      });
    }
    if (Object.keys(filters).length) {
      setPage(1);
      api_get(`instructors?page=${page}${window.location.search ? SearchUrlHandler(window.location.search) : options}&searchJoin=and`).then((data) => {
        setData(data);
      });
    }
  }, [filters, search, window.location.search]);

  useEffect(() => {
    if (!search && isSearch) {
      setIsSearch(false)
      setPage(1);
      getData();
    }
  }, [search]);

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
    localStorage.setItem('FilterButton', !filtersOpen)
  };
  const toggleColumns = () => {
    setColumnsOpen(!columnsOpen);
  };
  const handleSortChange = (accessor) => {
    sortChange({
      accessor: accessor,
      order: sort.order === 'asc' ? 'desc' : 'asc',
    });
  };
  const handlePageChange = (event, page) => {
    setPage(page + 1);
  };
  const getData = (perPage = 12) => {
    const options = optionsParser(search, filters, sort, fieldSearchable);
    api_get(`instructors?searchJoin=and&page=${page}${window.location.search ? SearchUrlHandler(window.location.search) : options}&perPage=${perPage}`).then((data) => {
      setData(data);
    });
  };
  const getListData = (perPage = 12) => {
    const options = optionsParser(null, null, sort, null);
    api_get(`instructors?page=${page}${options}&perPage=${perPage}`).then((data) => {
      setData(data);
    });
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    getData(event.target.value)
  };
  const handleExport = () => {
    const options = optionsParser(search, filters, sort, fieldSearchable);
    get_file(`instructors/export?searchJoin=and&page=${page}${options}`)
  }
  const classes = useStyles();

  if (!data.payload) {
    return (<Loading />);
  }
  return (
    <div className={classes.root}>
      <Toolbar toggleFilters={toggleFilters} toggleColumns={toggleColumns} searchChange={searchChange} pageLink='/users/teachers'
        searchMessage={'Search (ID, Name, Email and Phone)'} handleExport={handleExport} />
      <div className={classes.content}>
        <Collapse in={columnsOpen}>
          <Columns columnsChange={columnsChange} columns={columns} />
        </Collapse>
        <Collapse in={filtersOpen}>
          <Filter fields={fieldFilterable} values={filters} filtersChange={filtersChange} initializeData={getListData} />
        </Collapse>
        <Table columns={columns} data={data} handleSortChange={handleSortChange} sort={sort}
          handlePageChange={handlePageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          handleRowsPerPageChange={handleRowsPerPageChange}
          tableService={{ method: api_delete, base_url: `instructors` }}
          pageLink='/users/teachers' initializeData={getData} />
      </div>

    </div>
  );
};

export default TeacherList;
