// @flow
import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useParams } from "react-router-dom";
import SnackBar from "../../../components/SnackBar";
import Checkbox from "@material-ui/core/Checkbox";
import { DateTimePicker, LocalizationProvider } from "@material-ui/pickers";
import momentAdapter from "@material-ui/pickers/adapter/moment";
import Moment from "moment";
import { AsyncPaginate } from "react-select-async-paginate";
import theme from "../../../theme";
import mapStateToRequest from "../../../helpers/mapStateToRequest";
import QuillEditor from "../../../components/QuillEditor";
import FilesDropzone from "../../../components/FilesDropzone";
import { api_get, api_post_serialize } from "../../../utils/Api";
import moment from "moment";
import LoadingApi from "components/LoadingApi/LoadingApi";
import { getLevels, getTeachers, getGroups, getSubjects } from "../../../helpers/methods";
import optionsParser from "../../../helpers/optionsParser";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    overflow: "visible",
  },
  mtop: {
    marginTop: 24,
  },

  mtop0: {
    marginTop: 0,
  },
  content: {
    padding: 0,
  },
  uploadedFiles: {
    marginTop: 10,
  },
}));

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];
type Props = {
  edit: boolean,
  className: string,
};
const Form = (props: Props): React$Element<any> => {
  const { id } = useParams();
  const { className, edit, ...rest } = props;
  const classes = useStyles();
  const [duration, setDuration] = useState("02:00");
  const [values, setValues] = useState({
    content: {
      name: "",
      isDraft: false,
      active: false,
      description: "",
      instructor: "",
      groupContents: [],
      type: 5,
      isEventTitleApproved: false,
    },
    startDate: Moment().format("YYYY-MM-DD HH:mm"),
    isBigZoomEvent: false,
    isFromCalendar: false,
    webinarAccount: "",
    canceled: false,
    subjectEvents: null,
    url: "",
  });
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertSeverity] = useState("success");
  const [existZoomAccount, setExistZoomAccount] = useState(false);
  const [webinar, setWebinar] = useState(null);
  const [group, setGroup] = useState(null);
  const [url, setUrl] = useState(null);
  const [name, setName] = useState(null);
  const [Levels, setLevels] = useState(null);
  const [teacher, setTeacher] = useState(null);
  const [subject, setSubject] = useState(null);
  const [active, setActive] = useState(null);
  const [isDraft, setIsDraft] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setName(event.target.value);
    setValues({
      ...values,
      content: {
        ...values.content,
        [event.target.name]: event.target.value,
      },
    });
  };

  const onFileChange = (files) => {
    if (files && files.length > 0) {
      setValues({
        ...values,
        content: {
          ...values.content,
          contentFiles: files,
        },
      });
    }
  };
  const handleDescriptionChange = (value) => {
    setValues({
      ...values,
      content: {
        ...values.content,
        description: value,
      },
    });
  };
  const handleActiveCheckChange = (event) => {
    setActive(event.target.checked);
    setValues({
      ...values,
      content: {
        ...values.content,
        active: event.target.checked,
      },
    });
  };
  const handlePrivateCheckChange = (event) => {
    setIsDraft(event.target.checked);
    setValues({
      ...values,
      content: {
        ...values.content,
        isDraft: event.target.checked,
      },
    });
  };

  const handleCheckChangeInObject = (event) => {
    setValues({ ...values, isBigZoomEvent: event.target.checked });
  };
  const levelsChange = (options) => {
    setLevels(options);
    setValues({
      ...values,
      content: {
        ...values.content,
        contentStudentLevels: options,
      },
    });
  };

  const instructorChange = (value) => {
    if (value) {
      setTeacher(value);
      setValues({
        ...values,
        content: {
          ...values.content,
          instructor: value,
        },
      });
    }
  };
  const webinarChange = (value) => {
    if (value) {
      setWebinar(value);
      setValues({ ...values, webinarAccount: value });
    }
  };
  const groupsChange = (options) => {
    setGroup(options);
    setValues({
      ...values,
      content: {
        ...values.content,
        groupContents: options,
      },
    });
  };
  const subjectsChange = (options) => {
    setSubject(options);
    setValues({ ...values, subjectEvents: options });
  };

  const dateChange = (value) => {
    if (value) {
      setValues({
        ...values,
        startDate: moment(value, "YYYY/MM/DD HH:mm").format("YYYY-MM-DD HH:mm"),
      });
      handleChangeDuration(duration, value.format("YYYY-MM-DD HH:mm"));
    }
  };

  const urlChange = (event) => {
    setUrl(event.target.value);
    setValues({ ...values, url: event.target.value });
  };

  const showErrorMessage = (errors) => {
    Object.keys(errors).map((key, index) => {
      if (index == 0) {
        setAlertSeverity("error");
        errors.message
          ? setAlertMessage(errors[0].message)
          : setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };

  const showAlertMessage = (message) => {
    setAlertSeverity("error");
    setAlertMessage(message);
    setOpen(true);
  };
  const subjectFormat = (values) => {
    let newArr = [];
    let newArr2 = [];
    let newArr3 = [];
    let fileArr = [];

    values.subjectEvents &&
      values.subjectEvents.map((subject) => {
        newArr.push({
          subject: subject.subject ? subject.subject.id : subject.id,
        });
      });
    values.subjectEvents = newArr;
    values.webinarAccount = values.webinarAccount.id;
    values.content.instructor = values.content.instructor.id;
    values.content.active = values?.content?.active === true ? 1 : 0;
    values.content.isDraft = values?.content?.isDraft === true ? 1 : 0;
    values.canceled = values?.canceled === true ? 1 : 0;
    values.content.contentStudentLevels &&
      values.content.contentStudentLevels.map((option) => {
        newArr2.push({
          studentLevel: option.student_level
            ? option.student_level.id
            : option.id,
        });
      });
    values.content.contentStudentLevels = newArr2;
    values.content.groupContents &&
      values.content.groupContents.map((option) => {
        newArr3.push({ groups: option.groups ? option.groups.id : option.id });
      });
    values.content.groupContents = newArr3;

    if (values.content.contentFiles) {
      values.content.contentFiles.map((option) => {
        fileArr.push({
          position: 0,
          file: {
            file: option,
            name: option.name,
            attachment: 1,
            solution: 0,
            document: 0,
            marks: 0,
            homework: 0,
            teacher: values.content.instructor.id,
          },
        });
      });
      values.content.contentFiles = fileArr;
    }
    return values;
  };
  const clearDatas = (values) => {
    setValues({
      ...values,
      subjectEvents: subject,
      url: url,
      webinarAccount: webinar,
      content: {
        instructor: teacher,
        groupContents: group,
        contentStudentLevels: Levels,
        name: name,
        active: active == true ? 1 : 0,
        isDraft: isDraft == true ? 1 : 0,
      },
    });
  };
  const formValidation = (values) => {
    if (!values?.content?.name) {
      return { success: false, message: "Name is missing" };
    }
    if (
      values?.content?.name.length < 3 ||
      values?.content?.name?.length > 2000
    ) {
      return {
        success: false,
        message: "Name must be between 3 and 2000 characters",
      };
    }
    if (!values?.content?.instructor) {
      return { success: false, message: "Instructor is missing" };
    }
    if (!values.webinarAccount) {
      return { success: false, message: "WebinarAccount is missing" };
    }
    if (!values.subjectEvents || values.subjectEvents?.length <= 0) {
      return { success: false, message: "Subjects are missing" };
    }
  };
  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity("warning");
      setOpen(true);
    } else {
      const valuesState = subjectFormat(values);
      setLoading(true);
      const requestValues = mapStateToRequest(
        valuesState,
        ["content", "subjectEvents"],
        []
      );
      api_post_serialize(`events`, requestValues, true)
        .then((result) => {
          if (result.errors) {
            showErrorMessage(result.errors);
            clearDatas(values);
          } else if (result?.code !== 200 && result?.code !== 201) {
            showAlertMessage(
              result?.message
                ? result.message
                : "Technical error! Contact the Developers Team"
            );
            clearDatas(values);
          } else {
            setAlertSeverity("success");
            setAlertMessage("Event created successfully");
            setLoading(false);
            setOpen(true);
            window.location.href = "/contents/content-events";
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
  };

  const getWebinars = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['email'])
      .replace('&search=', ';')
      .replace('searchJoin=or', ';');
    const result = await api_get(`webinars?page=${page.page}&search=active:1${options}searchJoin=and`);
    const webinarAccounts = formatZoomAccounts(result.payload);
    return {
      options: [...webinarAccounts],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };
  const formatZoomAccounts = (webinarAccounts) => {
    //find zoom account
    let zoomAccount = webinarAccounts.find((webinar) =>
      checkIsZoomAccount(webinar.email)
    );
    //remove all zoom accounts
    let withoutZoomAccounts = webinarAccounts.filter(
      (webinar) => !checkIsZoomAccount(webinar.email)
    );
    if (zoomAccount && !existZoomAccount) {
      //add a zoom account
      withoutZoomAccounts.push(zoomAccount);
      setExistZoomAccount(true);
    }
    return withoutZoomAccounts;
  };
  const checkIsZoomAccount = (email) => {
    return email?.includes("zoom") || email?.includes("ostedhy");
  };

  const handleChangeDuration = (duration, startD = null) => {
    const newValues = { ...values };
    if (newValues.startDate && duration) {
      let date = startD || newValues.startDate;
      setDuration(duration);
      const endDate = Moment(date)
        .add(Moment.duration(duration).asMinutes(), "minute")
        .format("YYYY-MM-DD HH:mm");
      setValues({ ...newValues, endDate: endDate, startDate: date });
    }
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <Card className={classes.root}>
              <CardContent>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  onChange={handleChange}
                  required
                  value={values.content.name || ""}
                  variant="outlined"
                  size="small"
                />
                <Box mt={2} mb={1}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Description
                  </Typography>
                </Box>
                <Paper variant="outlined">
                  <QuillEditor
                    className={classes.editor}
                    value={values.content.description || ""}
                    modules={modules}
                    formats={formats}
                    onChange={(value) => handleDescriptionChange(value)}
                  />
                </Paper>
                <Box mt={2} mb={1}>
                  <TextField
                    fullWidth
                    label="Url"
                    name="url"
                    onChange={urlChange}
                    required
                    value={values.url || ""}
                    variant="outlined"
                    size="small"
                  />
                </Box>
              </CardContent>
            </Card>
            <Box mt={3}>
              <Card>
                <CardHeader title="Date & Duration" />
                <Divider />
                <CardContent>
                  <Box mt={0} mb={1}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={8}>
                        <LocalizationProvider dateAdapter={momentAdapter}>
                          <DateTimePicker
                            renderInput={(props) => (
                              <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                {...props}
                              />
                            )}
                            value={values.startDate}
                            inputFormat="DD/MM/yyyy HH:mm"
                            autoOk
                            ampm={false}
                            label="Start date"
                            onChange={dateChange}
                            name="startDate"
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <TextField
                          fullWidth
                          size="small"
                          label="duration"
                          name="duration"
                          onChange={(event) =>
                            handleChangeDuration(event.target.value)
                          }
                          required
                          value={duration || ""}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Box>
            <Box mt={3}>
              <Card>
                <CardHeader title="Upload Zone" />
                <Divider />
                <CardContent>
                  <Box mt={0} mb={1}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Files
                    </Typography>
                  </Box>
                  <FilesDropzone
                    values={values}
                    setValues={setValues}
                    onFileChange={onFileChange}
                    isMultiple={true}
                    isattachment={true}
                    isPdf={true}
                  />
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card className={classes.root}>
              <CardHeader title="Basic" />
              <Divider className={classes.mtop0} />
              <CardContent className={classes.mtop0}>
                <Box mt={0}>
                  <AsyncPaginate
                    loadOptions={getWebinars}
                    onChange={webinarChange}
                    getOptionLabel={(option) => option.email}
                    getOptionValue={(option) => option.id}
                    value={values.webinarAccount || ""}
                    placeholder="Webinars"
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        height: "53px",
                      }),
                      placeholder: (base) => ({
                        ...base,
                        color: "#000",
                        fontSize: theme.typography.fontSize,
                        fontFamily: theme.typography.fontFamily,
                      }),
                    }}
                    menuPortalTarget={document.querySelector("body")}
                  />
                </Box>
                {checkIsZoomAccount(values?.webinarAccount?.email) && (
                  <Box mt={0}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isBigZoomEvent}
                          onChange={handleCheckChangeInObject}
                          value={values.isBigZoomEvent}
                          name="isBigZoomEvent"
                          size="small"
                        />
                      }
                      label="Is big zoom account ?"
                    />
                  </Box>
                )}
                <Box mt={2}>
                  <AsyncPaginate
                    loadOptions={getTeachers}
                    value={values.content.instructor}
                    onChange={instructorChange}
                    getOptionLabel={(option) =>
                      option.name + " " + option.last_name
                    }
                    getOptionValue={(option) => option.id}
                    placeholder="Teachers"
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        height: "53px",
                      }),
                      placeholder: (base) => ({
                        ...base,
                        color: "#000",
                        fontSize: theme.typography.fontSize,
                        fontFamily: theme.typography.fontFamily,
                      }),
                    }}
                    menuPortalTarget={document.querySelector("body")}
                  />
                </Box>
              </CardContent>
            </Card>
            <Box mt={3}>
              <Card className={classes.root}>
                <CardHeader title="Organize" />
                <Divider className={classes.mtop0} />
                <CardContent className={classes.mtop0}>
                  <Box mt={0}>
                    <AsyncPaginate
                      loadOptions={getSubjects}
                      value={values.subjectEvents}
                      onChange={subjectsChange}
                      getOptionLabel={(option) =>
                        (option.subject
                          ? option?.subject?.division?.name +
                          "|" +
                          option?.subject?.name
                          : option?.division?.name + "|" + option.name) || ""
                      }
                      getOptionValue={(option) =>
                        option.subject ? option.subject.id : option.id
                      }
                      placeholder="Subjects"
                      isMulti
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: "53px",
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: "#000",
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector("body")}
                    />
                  </Box>
                  <Box mt={2}>
                    <AsyncPaginate
                      loadOptions={getGroups}
                      value={values.content.groupContents}
                      onChange={groupsChange}
                      getOptionLabel={(option) =>
                        option.groups ? option.groups.name : option.name
                      }
                      getOptionValue={(option) =>
                        option.groups ? option.groups.id : option.id
                      }
                      placeholder="Groups"
                      isMulti
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: "53px",
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: "#000",
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector("body")}
                    />
                  </Box>
                  <Box mt={2}>
                    <AsyncPaginate
                      loadOptions={getLevels}
                      value={values.content.contentStudentLevels}
                      onChange={levelsChange}
                      getOptionLabel={(option) =>
                        option.student_level
                          ? option.student_level.name
                          : option.name
                      }
                      getOptionValue={(option) =>
                        option.student_level
                          ? option.student_level.id
                          : option.id
                      }
                      placeholder="Levels"
                      isMulti
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: "53px",
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: "#000",
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector("body")}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Box>
            <Box mt={3}>
              <Card className={classes.root}>
                <CardHeader title="Status" />
                <Divider className={classes.mtop0} />
                <CardContent className={classes.mtop0}>
                  <Box mt={0}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.content?.active || ""}
                          onChange={handleActiveCheckChange}
                          value={values.content?.active || ""}
                          // name="active"
                          size="small"
                        />
                      }
                      label="Active ?"
                    />
                  </Box>
                  <Box mt={0}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.content.isDraft || ""}
                          onChange={handlePrivateCheckChange}
                          value={values.content.isDraft || ""}
                          //name="isDraft"
                          size="small"
                        />
                      }
                      label="Private ?"
                    />
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            Save details
          </Button>
        </Box>
      </form>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
      <LoadingApi open={loading} onClick={handleCloseLoading} />
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
  loading: PropTypes.bool,
};

export default Form;
